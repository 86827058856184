.slideshow {
  background-color: $secondary_color;
  color: $primary_color;
  max-height: 800px;
  margin: auto 0;

  &_modal {
    .slick-slider {
      width: 100%;

      .slick-track {
        display: flex;
      }
    }

    &_container {
      img {
        width: 100%;
        object-fit: contain;
        height: calc(100vh - 9rem);
      }
    }

    &_buttons {
      position: absolute;
      margin: 0 auto;
      display: flex;
      width: 100%;
      max-width: 300px;
      justify-content: space-between;
      bottom: 1rem;
      left: 0;
      right: 0;

      &_text {
        display: flex;
        align-items: center;
        font-size: 1.25rem;
        color: $primary_color;
      }

      div {
        display: flex;
      }
    }
  }

  &_img {
    img {
      width: 100%;
      object-fit: cover;
      min-height: 600px;
      max-height: 600px;

      @include size_M {
        min-height: 400px;
        max-height: 400px;
      }

      @include size_S {
        min-height: 300px;
        max-height: 300px;
      }

      @include size_XS {
        min-height: 200px;
        max-height: 200px;
      }
    }
  }

  &_info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 4rem;

    &_buttons {
      margin: 2rem auto 0 auto;
      display: flex;
      width: 100%;
      max-width: 300px;
      justify-content: space-between;

      &_text {
        display: flex;
        align-items: center;
        font-size: 1.25rem;
      }

      div {
        display: flex;
      }
    }
  }

  .slick-slider {
    width: 100%;

    .slick-slide {
      div {
        display: flex;
      }
    }
  }
}

.modal {
  & > .slick-slider {
    margin: 4.5rem auto;
    width: 100%;
  }

  &#slideshow {
    overflow: hidden;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
