.footer {
  position: relative;
  background: $complementary_color;
  color: $aux_color;

  p {
    color: $aux_color;
    opacity: 0.75;
    line-height: 1rem;
    margin: 0;
  }

  &_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 4rem;

    &_logo {
      margin-bottom: 3rem;
      cursor: pointer;

      img {
        height: 160px;
      }
    }

    &_contacts {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 2rem;
      width: 100%;
      max-width: 800px;
      margin-bottom: 3rem;

      &_contact {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        margin: 0 auto;
      }
    }

    &_bottom {
      p {
        font-size: 0.75rem;
        padding-top: 1rem;
      }
    }
  }
}
