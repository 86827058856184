@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-VariableFont'),
    url('../assets/fonts/Montserrat-VariableFont_wght.ttf') format('woff2');
  font-display: swap;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

:root {
  --app-height: 100vh;
  --anim-duration: 2000ms;
}

html {
  height: 100vh;
}

body {
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 400;
  height: 100svh;
  height: var(--app-height);
  background-color: $primary_color;
  box-sizing: border-box;
  scrollbar-gutter: stable both-edges;
}

h1 {
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 4rem;
  letter-spacing: 8px;
  text-transform: uppercase;

  @include size_S {
    font-size: 1.3rem;
    letter-spacing: 6px;
  }
}

h2 {
  font-size: 1.5rem;
  font-weight: 400;
  text-align: start;
  margin-bottom: 4rem;
  letter-spacing: 8px;
  text-transform: uppercase;

  @include size_S {
    font-size: 1.3rem;
    letter-spacing: 4px;
  }
}

h3 {
  font-size: 1.25rem;
  font-weight: 500;

  @include size_S {
    font-size: 1.1rem;
  }
}

p {
  font-size: 1rem;
}

a {
  color: inherit;
  text-decoration: none;
  transition: $transition_fast;
  cursor: pointer;
}

.m {
  &b {
    &_1 {
      margin-bottom: 1rem !important;
    }

    &_2 {
      margin-bottom: 2rem !important;
    }

    &_3 {
      margin-bottom: 3rem !important;
    }

    &_4 {
      margin-bottom: 4rem !important;
    }
  }
}

.body_wrapper {
  overflow-x: hidden;
}

.text_center {
  text-align: center !important;
}

.text_right {
  text-align: right !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.container {
  max-width: calc(1200px + 8rem);
  width: 100%;
  padding: 2rem 4rem;
  margin: 0 auto;

  @include size_S {
    padding: 1rem 2rem;
  }
}

.container_wide {
  max-width: calc(1920px + 8rem);
  width: 100%;
  padding: 1rem 2rem;
  margin: 0 auto;
}

.lock_scroll {
  overflow: hidden;
}

.opacity_transition_low {
  cursor: pointer;
  opacity: 1;
  transition: $transition_fast;

  &:hover {
    opacity: 0.5;
  }
}

.pointer {
  cursor: pointer;
}

.opacity_transition_high {
  cursor: pointer;
  opacity: 0.5;
  transition: $transition_fast;

  &:hover {
    opacity: 1;
  }
}

.slick-slide div {
  outline: none;
}

.title_underline {
  &_light {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      bottom: -16px;
      left: 0px;
      height: 3px;
      width: 120px;
      background-color: $primary_color;
    }

    &_small {
      position: relative;

      &::after {
        position: absolute;
        content: '';
        bottom: -8px;
        left: 0px;
        height: 2px;
        width: 100px;
        background-color: $secondary_color;
      }
    }
  }

  &_dark {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      bottom: -16px;
      left: 0px;
      height: 3px;
      width: 120px;
      background-color: $primary_color;
    }

    &_small {
      position: relative;

      &::after {
        position: absolute;
        content: '';
        bottom: -8px;
        left: 0px;
        height: 2px;
        width: 100px;
        background-color: $primary_color;
      }
    }
  }

  &_complementary {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      bottom: -16px;
      left: 0px;
      height: 3px;
      width: 120px;
      background-color: $complementary_color;
    }

    &_small {
      position: relative;

      &::after {
        position: absolute;
        content: '';
        bottom: -8px;
        left: 0px;
        height: 2px;
        width: 100px;
        background-color: $complementary_color;
      }
    }
  }
}

.button {
  display: flex;
  width: fit-content;
  margin: 2rem auto;
  color: $secondary_color;
  justify-content: flex-end;
  font-size: 1rem;
  font-weight: 600;

  div {
    display: flex;
    align-items: center;
    background-color: $primary_color;
    padding: 10px 20px;
    border-radius: 5px;
    transition: $transition_fast;
  }
}

.button_dark {
  display: flex;
  width: fit-content;
  margin: 2rem auto;
  color: $primary_color;
  justify-content: flex-end;
  font-size: 1rem;
  font-weight: 600;

  div {
    display: flex;
    align-items: center;
    background-color: $secondary_color;
    padding: 10px 20px;
    border-radius: 5px;
    transition: $transition_fast;
  }
}

.checkbox {
  &_container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 5px;
    height: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 0.75rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: $transition_fast;

    &:hover {
      input ~ .checkbox_checkmark {
        background-color: $complementary_color;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked {
        ~ .checkbox_checkmark {
          background-color: $complementary_color;
        }
        ~ .checkbox_checkmark::after {
          display: block;
        }
      }
    }
  }

  &_checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: $primary_color;
    transition: $transition_fast;

    &::after {
      content: '';
      position: absolute;
      display: none;
    }

    &::after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}