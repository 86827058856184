.home {
  position: relative;

  &_horizontal_scroll {
    @include size_XL {
      display: none;
    }
  }

  &_horizontal_scroll_unable {
    display: none;
    background-color: $secondary_color;
    color: $primary_color;

    @include size_XL {
      display: flex;
      flex-direction: column;
      padding: 6rem 4rem;

      p {
        font-size: 3rem;
      }
    }

    @include size_S {
      padding: 4rem 2rem;
    }
  }

  &_container_hero {
    display: flex;
    position: relative;
    height: calc(var(--app-height));
    transition: 200ms;

    img {
      width: 100%;
      object-fit: cover;
      overflow: hidden;
      object-position: 75% 0;
    }

    &_overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: 0.7;
      background: $complementary_color;
      background: linear-gradient(180deg, #ffffffdd 0%, #ffffff00 30%);
    }

    &_button {
      display: none;
      width: 100%;
      justify-content: center;
      text-align: center;
      position: absolute;
      margin: 0 auto;
      bottom: 4rem;

      @include size_M {
        display: flex;
      }
    }
  }
}
