#location {
  background-color: $secondary_color;
  color: $primary_color;
  padding-top: 2rem;

  h2 {
    text-align: center;
  }

  &_container {
    margin-bottom: 4rem;
    width: 100%;
    height: 600px;

    @include size_M {
      height: 400px;
    }
  }
}
