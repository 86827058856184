.accommodation {
  background-color: $primary_color;
  color: $secondary_color;
  padding-bottom: 6rem;

  @include size_S {
    padding-bottom: 0;
  }

  &_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    p {
      margin-top: 0;
    }

    &_slick {
      margin-top: 4rem;
      display: flex;
      flex-direction: column-reverse;

      &_title {
        text-align: center;
        margin-top: 2rem;
        text-align: center;
        height: 2rem;

        .slick-list {
          padding: 0 40px 0 40px;
        }

        .slick-prev {
          z-index: 1;
          left: 0;
          top: 8px;

          & svg {
            width: unset !important	;
          }

          &::before {
            content: unset;
          }
        }

        .slick-next {
          z-index: 1;
          right: 20px;
          top: 8px;

          &::before {
            content: unset;
          }
        }

        .slick-slide {
          opacity: 0.25;

          @include size_XS {
            div {
              padding: 0 25px;
            }
          }
        }

        .slick-center {
          font-weight: 600;
          color: $complementary_color;
          opacity: 1;
          font-size: 1.5rem;
          transition: $transition_fast;

          @include size_M {
            font-size: 1.3rem;
          }
        }
      }

      &_container {
        position: relative;
        width: 100%;
        z-index: 1;

        &_image {
          width: 100%;
          min-height: 560px;
          max-height: 560px;
          padding-bottom: 90px;
          object-fit: cover;

          @include size_XS {
            height: 300px;
            padding-bottom: 0px;
          }
        }

        &_box {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          justify-content: space-between;
          padding: 2rem;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          background-color: $aux_neutral_color;
          max-height: 180px;
          height: 100%;
          max-width: 380px;
          width: 100%;
          border-radius: 10px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

          @include size_XS {
            max-width: unset;
            padding: 0 2rem;
            position: relative;
            box-shadow: unset;
            border-radius: 0px;
          }

          &_title {
            font-weight: 500;
            letter-spacing: 4px;
            text-transform: uppercase;
            color: $secondary_color;

            @include size_XS {
              padding: 1rem 0;
            }
          }

          &_info {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: center;
            font-weight: 600;
          }

          &_links {
            display: flex;
            justify-content: center;
            gap: 20px;

            @include size_XS {
              margin: 1rem;
            }

            div,
            a {
              width: fit-content;
              position: relative;
              cursor: pointer;
              opacity: 1;

              &::after {
                content: '';
                position: absolute;
                bottom: -4px;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: $secondary_color;
                opacity: 1;
                transition: opacity 300ms, transform 300ms;
                transform: scale(0);
                transform-origin: center;

                @include size_XS {
                  transform: scale(1);
                }
              }

              &:hover::after,
              &:focus::after {
                transform: scale(1);
              }
            }
          }
        }
      }
    }
  }

  .slick-list {
    overflow: unset;
    overflow-x: clip;
  }

  @include size_XS {
    .title_underline_complementary_small {
      &::after {
        display: none;
      }
    }
  }
}
