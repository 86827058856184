$transition_fast: 250ms ease-in-out;
$transition: 500ms ease-in-out;
$transition_slow: 750ms ease-in-out;

$primary_color: #ffffff;
$secondary_color: #024b5a;
$complementary_color: #D4B55E;
$aux_color: #221B1D;
$aux_neutral_color: #eaeff3;

:export {
  primaryColor: $primary_color;
  secondaryColor: $secondary_color;
  complementaryColor: $complementary_color;
  auxColor: $aux_color;
  auxNeutralColor: $aux_neutral_color;
}
