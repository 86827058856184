.modal {
  display: flex;
  margin: auto 0;
  background-color: $primary_color;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  overflow: auto;

  &_close {
    display: flex;
    position: absolute;
    top: 1rem;
    right: 2rem;
    cursor: pointer;
    z-index: 10;

    @include size_S {
      right: 1rem;
    }
  }
}
