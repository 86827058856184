.discover {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: $primary_color;
  color: $secondary_color;
  transition: 500ms ease-in-out;

  &_container {
    padding-bottom: 4rem;

    p {
      margin-bottom: 1rem;
    }

    &_content {
      display: grid;
      grid-template-columns: calc(50% - 2rem) calc(50% - 2rem);
      gap: 4rem;

      @include size_M {
        grid-template-columns: 100%;
      }

      &_text {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;

        h2 {
          color: $complementary_color;
          margin: 0;
          margin-bottom: 2rem;
        }

        p {
          white-space: pre-line;
          margin-bottom: 1rem;
        }
      }

      &_buttons {
        display: flex;
        align-items: center;
        gap: 2rem;

        @include size_M {
          justify-content: center;
        }

        div {
          display: flex;
          cursor: pointer;
          transition: $transition;
        }

        &_counter {
          font-size: 1.25rem;
          margin: 0 0 0 auto;
        }
      }

      &_img {
        height: 100%;
        * {
          height: inherit;

          @include size_M {
            height: 300px;
          }
        }

        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .slick-active {
    z-index: 1;
  }
}
