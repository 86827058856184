.services {
  background-color: $primary_color;
  color: $secondary_color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-bottom: 6rem;

  .container{
    padding-top: 4rem;
  }

  &_container {
    display: grid;
    max-width: 1440px;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    gap: 2rem;
    margin: 0 auto;

    @include size_M {
      grid-template-columns: 1fr 1fr;
    }

    @include size_S {
      grid-template-columns: 1fr;
    }

    div {
      display: grid;
      grid-template-columns: 5% 95%;
      width: 100%;

      gap: 1rem;
    }
  }
}
