.header_lang {
  cursor: pointer;
  transition: $transition;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: $aux_color;
  font-weight: 600;
  padding: 12px;
  font-family: inherit;
  font-size: 1rem;
  border: none;

  &:focus {
    outline: none;
  }
}
